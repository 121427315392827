import type { Fetcher } from 'swr'
import { get, post } from './base'
import type { TenantResource, TenantResourceParams } from '@/models/workspace'

export const fetchTenantResourceDetail: Fetcher<TenantResource> = () => {
  return get<TenantResource>('/tenant_resource')
}

export const setTenantResource: Fetcher<TenantResource, TenantResourceParams> = (params) => {
  return post<TenantResource>('/tenant_resource', { body: params })
}
